import StudentsFinderClass from "./students-finder";
import AssigmentsFinderClass from "@src/assigment/application/assigments-finder.js";
import AssigmentsHistoricalFinderClass from "@src/assigment/application/assigments-historical-finder.js";

import moment from "moment";

export default class {
	constructor() {
		this.students_finder = new StudentsFinderClass();
		this.assigments_finder = new AssigmentsFinderClass();
		this.assigments_historical_finder = new AssigmentsHistoricalFinderClass();
	}

	async get_students_for_assigment(assigment_slug) {
		const selected_assigment =
			this.assigments_finder.get_assigment_by_slug(assigment_slug);
		const allowed_students =
			await this.students_finder.get_students_can_do_assigment(assigment_slug);
		const allowed_students_ids = allowed_students.map((student) => student.id);

		const allowed_students_historical_assigments =
			await this.assigments_historical_finder.get_assigments_historical_by_students_ids(
				allowed_students_ids
			);

		let assigments_grouped_by_assigned = {};
		allowed_students.forEach((student) => {
			assigments_grouped_by_assigned[student.id] = {
				student_id: student.id,
				student_name: student.full_name,
				latest_assigment: null,
				latest_selected_assigment: null
			};
		});

		allowed_students_historical_assigments.forEach((assigment) => {
			const assigned_id = assigment.assigned ? assigment.assigned.id : null;

			if (assigned_id) {
				if (
					assigments_grouped_by_assigned[assigned_id].latest_assigment ==
						null ||
					moment(
						assigments_grouped_by_assigned[assigned_id].latest_assigment
					).isBefore(assigment.date)
				) {
					assigments_grouped_by_assigned[assigned_id].latest_assigment =
						assigment.date;
				}

				if (
					assigment.assignment.id == selected_assigment.id &&
					(assigments_grouped_by_assigned[assigned_id]
						.latest_selected_assigment == null ||
						moment(
							assigments_grouped_by_assigned[assigned_id]
								.latest_selected_assigment
						).isBefore(assigment.date))
				)
					assigments_grouped_by_assigned[
						assigned_id
					].latest_selected_assigment = assigment.date;
			}

			//Check if student latest assigment is as companion
			const companion_id = assigment.companion ? assigment.companion.id : null;
			if (
				companion_id &&
				assigments_grouped_by_assigned[companion_id] &&
				(assigments_grouped_by_assigned[companion_id].latest_assigment ==
					null ||
					moment(
						assigments_grouped_by_assigned[companion_id].latest_assigment
					).isBefore(assigment.date))
			) {
				assigments_grouped_by_assigned[companion_id].latest_assigment =
					assigment.date;
			}
		});

		//Add students that never did this assigment
		allowed_students.forEach((student) => {
			if (!assigments_grouped_by_assigned[student.id])
				assigments_grouped_by_assigned[student.id] = {
					student_id: student.id,
					student_name: student.full_name,
					latest_assigment: null,
					latest_selected_assigment: null
				};
		});

		let assigments_grouped_by_assigned_as_array = [];
		for (let student_id in assigments_grouped_by_assigned) {
			assigments_grouped_by_assigned_as_array.push(
				assigments_grouped_by_assigned[student_id]
			);
		}

		return assigments_grouped_by_assigned_as_array;
	}

	_group_array_by_property(xs, key) {
		return xs.reduce(function (rv, x) {
			(rv[x[key]] = rv[x[key]] || []).push(x);
			return rv;
		}, {});
	}

	_get_stored_students() {
		return this.store_interface.get(this.entity_name);
	}

	async get_companions_for_assigment(gender) {
		const students_by_gender =
			await this.students_finder.get_students_by_gender(gender);

		const students_ids = students_by_gender.map((student) => student.id);

		let total_assigments = [];
		const students_compaions_historical =
			await this.assigments_historical_finder.get_companions_historical_by_students_ids(
				students_ids
			);

		const students_assigments_historical =
			await this.assigments_historical_finder.get_assigments_historical_by_students_ids(
				students_ids
			);

		total_assigments = students_compaions_historical.concat(
			students_assigments_historical
		);

		let assigments_by_companion =
			this._group_assigments_by_companion(total_assigments);

		//Remove not selected gender students
		let assigments_from_gender_students = assigments_by_companion.filter(
			(data) => students_ids.find((id) => id == data.student_id)
		);

		//Add students that never did any assigment
		if (assigments_from_gender_students.length != students_ids.length) {
			for (let index in students_by_gender) {
				const student = students_by_gender[index];
				const student_id = student.id;

				const student_has_made_any_assigment =
					assigments_from_gender_students.find(
						(data) => data.student_id == student_id
					);

				if (!student_has_made_any_assigment) {
					assigments_from_gender_students.push({
						student_id: student_id,
						student_name: student.full_name,
						latest_companion: null,
						latest_assigment: null
					});
				}
			}
		}

		return assigments_from_gender_students;
	}

	_group_assigments_by_companion(assigments) {
		var assigned_students_data = {};

		for (let index in assigments) {
			const assigment = assigments[index];

			if (!assigment.assigned) continue;

			const assigned_id = assigment.assigned.id;
			const companion_id = assigment.companion ? assigment.companion.id : null;
			const assigment_date = moment(assigment.date).toDate();

			if (!assigned_students_data[assigned_id]) {
				assigned_students_data[assigned_id] = {
					student_id: assigned_id,
					student_name: assigment.assigned.value,
					latest_companion: null,
					latest_assigment: assigment_date
				};
			} else if (
				assigned_students_data[assigned_id].latest_assigment == null ||
				moment(assigned_students_data[assigned_id].latest_assigment).isBefore(
					assigment_date
				)
			) {
				assigned_students_data[assigned_id].latest_assigment = assigment_date;
			}

			if (companion_id) {
				if (!assigned_students_data[companion_id]) {
					assigned_students_data[companion_id] = {
						student_id: companion_id,
						student_name: assigment.companion.value,
						latest_companion: assigment_date,
						latest_assigment: assigment_date
					};
				} else if (
					!assigned_students_data[companion_id].latest_companion ||
					moment(
						assigned_students_data[companion_id].latest_companion
					).isBefore(assigment_date)
				) {
					assigned_students_data[companion_id].latest_companion =
						assigment_date;
				} else if (
					!assigned_students_data[companion_id].latest_assigment ||
					moment(
						assigned_students_data[companion_id].latest_assigment
					).isBefore(assigment_date)
				) {
					assigned_students_data[companion_id].latest_assigment =
						assigment_date;
				}
			}
		}

		return Object.values(assigned_students_data);
	}
}
