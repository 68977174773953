import Joi from "joi";
import Validator from "@src/shared/domain/validator";

const relationed_object_schema = Joi.object({
	id: Joi.number(),
	value: Joi.any(),
	order: Joi.any()
});

const schema = Joi.object({
	id: Joi.number(),
	name: Joi.string(),
	date: Joi.date(),
	assigned: relationed_object_schema,
	study: relationed_object_schema,
	companion: relationed_object_schema,
	assignment: relationed_object_schema,
	circumstances: Joi.string()
});

export default new Validator(schema);
